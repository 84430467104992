<template>
  <div class="serviceContainer mt-12">
    <div id="service" class="serviceHeader">
      <h2  class="text-center text-white text-4xl font-bold mb-8">Services</h2>
    </div>
    <div class="serviceCards grid md:grid-cols-3 text-center gap-5">
      <div class="service1 text-white relative bg-blue-600 border-solid border-2 border-gray-600 rounded-xl py-8 px-4 bg-gradient-to-r from-blue-600 via-blue-400 to-blue-600 shadow-lg shadow-blue-700">
        <div class="absolute top-0 rounded-t-xl left-0 w-full h-2 bg-yellow-600"></div>
        <h3 class="text-xl text-gray-900 font-bold">PREPERATION FOR MOVING</h3>
        <div class="my-4">
        <p class="text-xl font-semibold text-gray-800">Prices</p>
        <p class="text-lg text-gray-100">£99 + materials for the first hour</p>
        <p class="text-lg">£25 + materials for every 1/4 hour after first hour</p>
      
      </div>
        <h2 class="my-2 text-xl font-semibold text-gray-800">Whats included</h2>
        <ul>
          <li class="text-lg mb-2" v-for="service in service1" :key="service">{{ service }}</li>
        </ul>
      </div>
      <div class="service2 text-white bg-blue-600 border-solid border-2 border-gray-600 rounded-xl bg-gradient-to-r from-blue-600 via-blue-400 to-blue-600 shadow-lg shadow-blue-700">
        <div class="relative top-0 rounded-t-xl left-0 w-full h-2 bg-yellow-600 mb-8"></div>
        <h3 class="text-xl text-gray-900 font-bold">MOVED TO A NEW PROPERTY</h3>
        <div class="my-4">
        <p class="text-xl font-semibold text-gray-800">Prices</p>
        <p class="text-lg">£99 + materials for the first hour</p>
        <p class="text-lg">£25 + materials for every 1/4 hour after first hour</p>
        </div>
        <h2 class="my-2 text-xl font-semibold text-gray-800">Whats included</h2>
        <ul>
          <li class="text-lg mb-2" v-for="service in service2" :key="service">{{ service }}</li>
        </ul>
      </div>
      <div class="service2 text-white bg-blue-600 border-solid border-2 border-gray-600 rounded-xl bg-gradient-to-r from-blue-600 via-blue-400 to-blue-600 shadow-lg shadow-blue-700">
        <div class="relative top-0 rounded-t-xl left-0 w-full h-2 bg-yellow-600 mb-8"></div>
        <div class="">
        <h3 class="text-xl text-gray-900 font-bold mb-4">LOCKSMITH SERVICES</h3>
        <p class="text-xl text-white font-semibold">Quotation available on request</p>
        </div>
        <h2 class="my-2 text-xl font-semibold text-gray-800">Whats available</h2>
        <ul>
          <li class="text-lg mb-2" v-for="service in service3" :key="service">{{ service }}</li>
        </ul>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
setup() {
  const service1 = [
    'Disconnecting all appointed electrical lighting / fittings and appliances. Making safe with replacement pendant lights / batten holders and electrical fittings', 'Disconnecting all appointed plumbing appliances, i.e. washing machines / dishwashers. Fitting transit bolts where required, preparing hoses / cables ready for moving'
  ]

  const service2 = [
    'Installing all appointed electrical lighting / fittings and appliances.','Testing all new fitted electrical work', 'Installing all appointed plumbing appliances, i.e. washing machines / dishwashers.', ' Testing all new fitted plumbing work.'
  ]

  const service3 = [
    'Insurance assessments of all existing door/window/garage locks',
    'Replacement night latch barrels / deadlock levers with new keys',
    'Replacement front door night latches/deadlocks/UPVC cylinder locks/window locks',
    'Fit new front door night latches/deadlocks/window locks',
    'Damage free door entry for lost keys/locked doors'
  ]

  return {service1, service2, service3}
}
}
</script>

<style>

</style>