<template>
  <div class="hero w-full bg-center bg-cover h-[32rem]">
            <div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
                <div class="text-center">
                    <h1 class="text-2xl font-semibold text-white lg:text-3xl">KMG HomePrep LTD is a company designed to take care of all <span>Electrical, Plumbing + Locksmith work</span> for <span>Property Movers!</span></h1>
                    <p class="text-white font-semibold text-xl mt-4">Serving <span class="underline decoration-yellow-600 hover:no-underline">Home Counties + London</span></p>
                    <button class="w-full px-4 py-2 mt-4 text-sm font-medium text-white uppercase transition-colors duration-200 transform bg-blue-600 rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"><a href="#contact">Get In Touch</a></button>
                </div>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>
.hero {
  background-image: url('~@/assets/img/interior.jpg');
}
</style>