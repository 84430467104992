<template>
  <div id="about" class="aboutContainer grid lg:grid-cols-2 text-white mx-4 lg:mt-12">
  
  <h1 class="text-4xl my-6 mr-6 text-center font-bold">OVER <span class="text-yellow-600">30 YEARS</span> EXPERIENCE IN HOME PREPERATION!</h1>
  <div class="text-center font-semibold">
  <p class="mb-2">Whether you're about to move home or have just moved in KMG Home Prep are here to help. We are experts in helping you leave or set up a new home.</p>
  <p class="mb-2">If your moving home, we can assist with disconnecting electrical fixings and appliances as well as safely disconnecting and preparing plumbing appliances ready for your big move.</p>
  <p class="mb-2">Just moving in? No worries we can help with that as well! Assistance with safely installing all electrical lights, fitting and appliances and fitting all plumbing appliances.</p>
  <p class="mb-2">We also offer locksmith services by fully qualified and trained locksmiths.</p>
</div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>