<template>
  <div>
    <div class="bg-gradient-to-r from-blue-800 via-blue-600 to-blue-800">
      <nav
        class="
          container
          px-6
          py-8
          mx-auto
          md:flex md:justify-between md:items-center
        "
      >
        <div class="flex items-center justify-between">
          <router-link
            to="/"
            class="
              font-bold
              text-gray-100
              md:text-2xl
              hover:text-gray-300
            "
            ><img src="@/assets/img/blankLogo.png" class="object-cover w-40">
          </router-link>
          <!-- Mobile menu button -->
          <div @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="
                text-gray-100
                hover:text-gray-400
                focus:outline-none focus:text-gray-400
              "
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="
            flex-col
            mt-8
            space-y-4
            md:flex
            md:space-y-0
            md:flex-row
            md:items-center
            md:space-x-10
            md:mt-0
          "
        >
          <!-- <a href="#top" class="font-bold text-gray-200 hover:text-blue-400">
            Home
          </a> -->
          <a href="#about" class="font-bold text-gray-100 hover:text-blue-400">
            About
          </a>
          <a href="#service" class="font-bold text-gray-100 hover:text-blue-400">
            Services
          </a>
          <a href="#contact" class="font-bold text-gray-100 hover:text-blue-400">
            Contact
          </a>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>