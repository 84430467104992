<template>
  <div class="mx-auto">
  
  <footer class="p-4 bg-blue-700 shadow flex items-center justify-between md:p-6 border-t-2 border-yellow-600">
    <span class="text-xs text-gray-300 sm:text-center dark:text-gray-400">© 2022 <a href="https://flowbite.com" class="hover:underline" target="_blank">KMG Home Prep ltd™</a>. Company Number 15265928. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 sm:mt-0">
      <li>
        <a href="#about" class="mr-4 text-sm text-gray-300 hover:underline md:mr-6 dark:text-gray-300">About</a>
      </li>
      <!-- <li>
        <a href="#" class="mr-4 text-sm text-gray-300 hover:underline md:mr-6 dark:text-gray-400">Privacy
          Policy</a>
      </li> -->
      <li>
        <a href="#service" class="mr-4 text-sm text-gray-300 hover:underline md:mr-6 dark:text-gray-400">Services</a>
      </li>
      <li>
        <a href="#contact" class="text-sm text-gray-300 hover:underline dark:text-gray-400">Contact</a>
      </li>
    </ul>
  </footer>
  </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>