<template>
  <div class="home">
    <Hero/>
    <About/>
    <ServicesVue/>
    <ContactVue/>
  </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import About from "@/components/About.vue";
import ServicesVue from '../components/ServicesVue.vue';
import ContactVue from '../components/ContactVue.vue'
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    Hero,
    About,
    ServicesVue,
    ContactVue
}
}
</script>
