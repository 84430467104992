<template>
  <h1 id="contact" class="text-center text-white font-bold text-4xl my-8">Ready to book or have a question...</h1>
  <div class="container mx-auto md:px-20 ">
<div class="">
<div class="relative text-white relative bg-blue-600 border-solid border-2 border-gray-600 rounded-xl py-8 bg-gradient-to-r from-blue-600 via-blue-400 to-blue-600 shadow-lg shadow-blue-700 max-w-xl p-8 mx-auto mb-8 bg-white rounded-md shadow-2xl" style="cursor: auto;">
  <div class="items-center text-center xs:flex xs:text-left xs:space-x-10">
    <!-- <img class="w-24 h-full mx-auto mb-4 -mt-12 transform -translate-y-2 xs:mb-0 xs:mt-0 xs:mx-none md:w-32" src="https://stackdiary.com/140x100.png" alt="John Doe" height="122" width="116"> -->
    <div class="flex-1 leading-chillaxed">
      <div class="mb-4">
        <strong class="block text-xl">Get in Touch</strong>
        <p>Open Monday - Friday</p>
        <p>8am - 5pm</p>
      </div>
      <ul>
        <li>
          <a href="tel:07944541020" class="inline-block h-12 -my-4 xs:h-auto underline decoration-yellow-600 hover:no-underline">
            <svg class="inline-block w-4 h-4 mr-3 opacity-40" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
            </svg>+44 (0) 7944541020
          </a>
        </li>
        <li>
          <svg class="inline-block w-4 h-4 mr-3 opacity-40" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
          </svg>
          <a class="inline-block h-12 -my-4 xs:h-auto underline decoration-yellow-600 hover:no-underline" href="mailto:kmghomeprepltd@gmail.com">kmghomeprepltd@gmail.com</a>
        </li>
        <li>
          <!-- <svg class="inline-block w-4 h-3 mr-3 -mt-1 opacity-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"></path>
          </svg> -->
          <!-- <a class="inline-block h-12 -my-4 xs:h-auto underline hover:no-underline" href="#" target="_blank" rel="noreferrer noopener">LinkedIn</a> -->
        </li>
      </ul>
    </div>
  </div>
  <div class="absolute top-0 rounded-t-xl left-0 w-full h-2 bg-yellow-600 "></div>
</div>
</div>
</div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>